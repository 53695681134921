import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Tooltip, OverlayTrigger, FormGroup, FormLabel } from 'react-bootstrap';
import { Calculate } from 'utils/helpers/calculation.functions';
import './Calculations.scss';


interface IProps {
  newData?: any;
  fieldData: any;
  setNewData?: any;
  name: string;
  labelText?: string;
  toolTipText?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  required?: boolean;
  isDate?: boolean;
  oneField?: any;
  isGrid?: boolean;
  data: any;
  setChangedFieldsId?: React.Dispatch<React.SetStateAction<any>>;
  gridColumnIndex?: number;
}

const CalculationField = ({
  newData,
  fieldData,
  setNewData,
  name,
  labelText = '',
  toolTipText = '',
  disabled = false,
  fullWidth = false,
  required = false,
  isDate = false,
  setChangedFieldsId,
  oneField,
  isGrid,
  data,
  gridColumnIndex
}: IProps) => {
  const [field, meta] = useField(name);
  const { setFieldValue } = useFormikContext();

  let value = isDate
    ? field.value
      ? new Date(field.value).toString().substring(4, 15)
      : ''
    : field.value || 0;

  const [calculatedValue, setCalculatedValue] = useState(0);

  useEffect(() => {

    console.log(calculatedValue, "Calculated value to be set in field");
  }, [calculatedValue])

  const pattern = /percentage/;
  const match = pattern.exec(field.name);
  const percentageWord = match ? match[0] : '';

  const changeFieldsValue = checkedValue => {
    const findField = newData?.Pages?.map(tab =>
      tab?.Fields?.forEach(f => {
        if (f.Template.ID === oneField.Template.ID) {
          f.Value = +checkedValue || 0;
        }
      }),

    );
    // if(+checkedValue){

    //   setFieldValue(field.name, +checkedValue)
    // }
    // else{
    //   setFieldValue(field.name, null)
    // }
  };
  return (
    <FormGroup className={`form-group ${fullWidth ? 'full-width' : ''}`}>
      <FormLabel className={`label-wrapper ${toolTipText && 'with-tooltip'}`}>
        {required && <span className="required-indicator">*</span>}
        {labelText}
      </FormLabel>
      <div className="calculated-field-grid">
        <div className={`${percentageWord ? 'input-with-postfix' : ''}`}>
          <input
          style={{textAlign: 'end', paddingRight: "0"}}
            type="number"
            id={field.name}
            className={`form-control ${meta.error && 'is-invalid'}`}
            {...field}
            value={value}
            disabled={true}
            onChange={(e) => {
              
            }
          }
          />
          {meta.error && <p className="error-wrapper">{meta.error}</p>}
          {percentageWord ? <span className="postfix">{`%`}</span> : <></>}
        </div>
        <button
          className="btn action-primary calculate-button"
          onClick={() => {
            
            const calculated = Calculate(fieldData, newData, gridColumnIndex);
           
            setCalculatedValue(calculated); // To update the state and trigger useEffect
            setFieldValue(name, calculated);
            changeFieldsValue(calculated);
            setChangedFieldsId(prev => {
              if (!prev.includes(isGrid ? data.Guid : field.name)) {
                if (isGrid) {
                  return [...prev, data.Guid];
                } else {
                  return [...prev, field.name];
                }
              }
              return prev;
            });
          }
        }
        >
          Calculate
        </button>
      </div>
    </FormGroup>
  );
};

export default CalculationField;
