export const isCalculationResultField = field => {
  if (
    field.Template.FieldType.Name === 'Calculated' &&
    field.Template.Parameters.Expression
  ) {
    return true;
  }
  return false;
};
const extractDefaultValue = (expression: string) => {
  const lastCommaIndex = expression.lastIndexOf(',');
  const lastParenIndex = expression.lastIndexOf(')');
  if (
    lastCommaIndex === -1 ||
    lastParenIndex === -1 ||
    lastCommaIndex > lastParenIndex
  ) {
    return null; // Invalid format or no default value
  }
  return expression.substring(lastCommaIndex + 1, lastParenIndex).trim();
};
const removeIFNAAndDefaultValue = expression => {
  if (!expression.startsWith('IFNA(')) {
    return expression; // Return original expression if it doesn't start with 'IFNA('
  }

  const lastCommaIndex = expression.lastIndexOf(',');
  if (lastCommaIndex === -1) {
    return expression.substring(5, expression.length - 1); // Remove 'IFNA(' and last ')' if no comma
  }

  return expression.substring(5, lastCommaIndex);
};

export const Calculate = (
  field: any,
  newData: any,
  gridColumnIndex: number,
) => {
  console.log(gridColumnIndex, 'GRID COLUMN INDEX');
  const regex = /(?=.*)[a-zA-Z0-9][a-zA-Z0-9-]+[_0-9]+/g;

  const expression = field.Template.Parameters.Expression;
  const expresionType = expression.split('(')[0];
  console.log(expression, 'EXPRESSION');

  const matchFields = expression.match(regex);

  const calculationValues = findFields(matchFields, newData);
  console.log(
    calculationValues,
    'FOUND FIELDS FROM ASSESMENT WITH NAMES IN EXPRESSION',
  );
  let calculationResult = 0;
  switch (expresionType) {
    case 'SUM':
      calculationResult = calculateSUM(calculationValues);
      break;
    case 'AVG':
      calculationResult = calculateAVG(calculationValues);
      break;
    case 'IFNA':
      let defaultValue = extractDefaultValue(expression);
      const matchDefaultValue = defaultValue.match(regex);
      console.log(
        matchDefaultValue,
        'If default value is field, value of this field should be default',
      );
      if (matchDefaultValue) {
        let arrayDefault = findFields(matchDefaultValue, newData);
        defaultValue = arrayDefault[0].value;
      }
      console.log(defaultValue, 'default value of IFNA function');
      calculationResult = calculateIFNA(
        calculationValues,
        expression,
        gridColumnIndex,
        defaultValue,
      );
      break;
    case 'DATEDIFFYEARS':
      calculationResult = calculateDATEDIFFYEARS(calculationValues);

      break;
    case 'DATEDIFFMONTHS':
      calculationResult = calculateDATEDIFFMONTHS(calculationValues);
      break;
    case 'DATEDIFFDAYS':
      calculationResult = calculateDATEDIFFDAYS(calculationValues);
      break;
    default:
      calculationResult = calculateSimpleExpressions(
        expression,
        calculationValues,
        gridColumnIndex,
      );
      break;
  }
  // let 1 = calculateSimpleExpressions(expression,calculationValues)
  const rounded = Math.round(calculationResult * 100) / 100;

  console.log(
    rounded,
    'Result to be returned by clicking calculation button',
  );

  return rounded;
};

const findFields = (fieldNames, newData) => {
  console.log(fieldNames, 'CALCULATION FILED NAMES TO SEARCH');
  let values = [];
  fieldNames?.forEach(fieldName => {
    newData?.Pages?.forEach(page => {
      page?.Fields?.forEach(field => {
        if (field.Template.CalcFieldName === fieldName) {
          values.push({
            value: field.Value ? field.Value : 0,
            fieldName: field.Template.CalcFieldName,
          });
        }
        if (field.GridChildren.length) {
          field?.GridChildren?.forEach(gridChildFields => {
            gridChildFields.forEach(gridChildField => {
              if (gridChildField.Template.CalcFieldName === fieldName) {
                // console.log(gridChildField.Value)
                values.push({
                  value: gridChildField.Value ? gridChildField.Value : 0,
                  fieldName: gridChildField.Template.CalcFieldName,
                });
              }
            });
          });
        }
      });
    });
  });

  return values;
};
const calculateSUM = values => {
  let sum = 0;
  values.forEach(value => {
    sum += Number(value.value);
  });
  console.log(sum, 'Value Of SUM calculation');
  return sum;
};

const calculateAVG = values => {
  let avg = 0;
  let amountOfValues = 0;
  values.forEach(value => {
    avg += Number(value.value);
    if (value.value !== 0) {
      amountOfValues++;
    }
  });
  console.log(amountOfValues, 'Amount of values for AVG');
  console.log(avg / amountOfValues, 'Value Of AVG calculation');
  return avg / amountOfValues;
};

const calculateSimpleExpressions = (
  expression: string,
  valuesForCalculations: { value: number; fieldName: string }[],
  gridColumnIndex: number,
) => {
  let valueMap = new Map<string, number[]>();
  valuesForCalculations.forEach(item => {
    if (item.fieldName && item.value !== undefined) {
      if (!valueMap.has(item.fieldName)) {
        valueMap.set(item.fieldName, []);
      }
      valueMap.get(item.fieldName).push(item.value);
    }
  });

  let numericExpression = expression;
  let fieldNames = Array.from(valueMap.keys()).sort(
    (a, b) => b.length - a.length,
  );
  for (let fieldName of fieldNames) {
    if (fieldName) {
      let valuesArray = valueMap.get(fieldName);
      let valueToUse =
        valuesArray.length > 1
          ? valuesArray[Math.min(gridColumnIndex, valuesArray.length - 1)]
          : valuesArray[0];

      let re = new RegExp(
        '\\b' + fieldName.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&') + '\\b',
        'g',
      );
      numericExpression = numericExpression.replace(re, valueToUse.toString());
    }
  }

  console.log(numericExpression, 'Simple Expression to be calculated');
  try {
    let result = eval(numericExpression);
    console.log(result, 'Result of simple Calculation');
    if (result === 0) {
      return 0;
    }
    return result;
  } catch (e) {
    console.error('Error evaluating expression: ', e);
    return 0;
  }
};

const calculateIFNA = (
  calculationValues: { value: number; fieldName: string }[],
  expression: string,
  gridColumnIndex: number,
  defaultValue: string,
) => {
  if (!calculationValues.length) {
    return defaultValue;
  }
  const expressionToCalculate = removeIFNAAndDefaultValue(expression);
  if (!expressionToCalculate) {
    return defaultValue;
  }
  console.log(expressionToCalculate, 'IFNA expression to be calculated');
  const result = calculateSimpleExpressions(
    expressionToCalculate,
    calculationValues,
    gridColumnIndex,
  );
  console.log(result, 'IFNA expression result');
  if (!result) {
    return defaultValue;
  }
  return result;
};

const calculateDATEDIFFYEARS = calculationValues => {
  try {
    if (!Array.isArray(calculationValues) || calculationValues.length !== 2) {
      console.error('Invalid calculationValues format or length');
      return 0;
    }

    const date1 = new Date(calculationValues[0].value);
    const date2 = new Date(calculationValues[1].value);

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      console.error('Invalid date format in calculationValues');
      return 0;
    }
    console.log(
      date2.getFullYear() - date1.getFullYear(),
      'Result to be returned from DATEDIFFYEARS function',
    );
    const diffYears = date2.getFullYear() - date1.getFullYear();
    return diffYears;
  } catch (e) {
    console.error('Error in DATEDIFFYEARS calculation: ', e);
    return 0;
  }
};
const calculateDATEDIFFMONTHS = calculationValues => {
  try {
    if (!Array.isArray(calculationValues) || calculationValues.length !== 2) {
      console.error('Invalid calculationValues format or length');
      return 0;
    }

    const date1 = new Date(calculationValues[0].value);
    const date2 = new Date(calculationValues[1].value);

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      console.error('Invalid date format in calculationValues');
      return 0;
    }

    const diffMonths =
      date2.getMonth() +
      12 * date2.getFullYear() -
      (date1.getMonth() + 12 * date1.getFullYear());
    console.log(diffMonths, 'Result in calculation of DATEDIFFMONTHS');
    return diffMonths;
  } catch (e) {
    console.error('Error in DATEDIFFMONTHS calculation: ', e);
    return 0;
  }
};

const calculateDATEDIFFDAYS = calculationValues => {
  try {
    if (!Array.isArray(calculationValues) || calculationValues.length !== 2) {
      console.error('Invalid calculationValues format or length');
      return 0;
    }

    const date1 = new Date(calculationValues[0].value);
    const date2 = new Date(calculationValues[1].value);

    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      console.error('Invalid date format in calculationValues');
      return 0;
    }
    console.log(date1.getTime(), date2.getTime());

    const timeDiff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    console.log(diffDays, 'Result in calculation of DATEDIFFDAYS');
    return diffDays;
  } catch (e) {
    console.error('Error in DATEDIFFDAYS calculation: ', e);
    return 0;
  }
};
