import React from 'react';
import "./PrivacyPage.scss";

export const PrivacyPage = () => {
  return (
    <div className="page-content">
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="privacy-wrapper">
              <div className="privacy-block">
                <h2 className="privacy-heading">Privacy Policy and Terms of Use</h2>
                <div className="privacy-text">
                  <p>Thank you for visiting our website. We respect your privacy and are committed to
                  protecting personal data that you share with us at this website. This privacy policy
                  informs you about how we look after your personal data when you visit our website
                  (regardless of where you visit it from) and gives you important information about
                your privacy rights.</p>
                  <p>This privacy policy explains how we protect and use the personal data that we collect
                  at
                  this website. Information included in this policy is designed to explain why we
                  collect
                  your personal data, how we access and use it, and how we store it to keep it safe.
                  Please read this privacy policy before using the website or submitting any personal
                data here.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">Who is Alltech and why does Alltech have a Privacy Policy posted here?</h3>
                <div className="privacy-text">
                  <p>Founded in 1980 by Irish entrepreneur and scientist Dr. Pearse Lyons, Alltech
                  discovers and delivers solutions for the sustainable nutrition of plants,
                  animals and people. With expertise in yeast fermentation, solid state
                  fermentation and the science of nutrigenomics, Alltech is a leading producer and
                  processor of yeast additives, organic trace minerals, feed ingredients, premix
                  and feed. Alltech, Inc has its global company headquarters located at 3031
                  Catnip Hill Road, Nicholasville, Kentucky 40356, USA, as well as a European
                  headquarters located at All-Technology (Ireland) Limited Alltech Bioscience
                  Centre, Summerhill Road, Sarney, Dunboyne Co. Meath. Alltech collects and stores
                  the personal data you share at this website. Alltech may share your personal
                  data internally to other members of the Alltech Group and with its service providers who assist Alltech with providing you specific services.</p>
                  <p>Alltech Inc (“the Company”) is made up of different legal entities, which all
                  form part of a wider group of companies (“the Alltech Group”) details of which
                  can be found here. This privacy policy is written on behalf of the Alltech
                  Group. When we mention "Alltech ", "we", "us" or "our" in this privacy policy,
                  we mean the relevant company in the Alltech Group responsible for processing
                  your data. This privacy policy applies to all Alltech websites and companies that link to or reference this Privacy Policy.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">Is it possible to get additional information about privacy policies related to other Alltech websites?</h3>
                <div className="privacy-text">
                  <p>If you would like to view additional information on our privacy policies that are
                  related to other Alltech websites or affiliated privacy statements, please click
                  the privacy policy links on the respective websites for these notices or affiliated statements.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">Does Alltech have to collect personal data by law?</h3>
                <div className="privacy-text">
                  <p>We will ask for your consent before we collect your personal data for specific
                  purposes. In general, we collect data only for the purpose of website analytics.
                  We do not use IP addresses to identify individual users. When we are required to
                  collect personal data by law or regulation in order to perform a specific
                  service or provide to you a specific product, we will need your consent before
                  we can perform the related service or provide you with the product you have
                  contracted with us to receive. When we enter into a contract with you, you can
                  expect that our contract with you will include information about how we protect your privacy.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">Does Alltech follow privacy and data protections like the GDPR?</h3>
                <div className="privacy-text">
                  <p>Alltech uses all reasonable efforts to process data in accordance with the laws
                  of the countries in which we operate, including the European Union’s General
                  Data Protection Regulation. (GDPR) We take appropriate measures to protect the
                  personal data you provide to Alltech. Personal data includes any information
                                    relating to an identified or identifiable natural person or data subject.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">What happens if Alltech changes the way it protects my personal data?</h3>
                <div className="privacy-text">
                  <p>If we make changes in our practices to further enhance the way we protect your
                  personal data, we will update this policy and post the update at this website.
                  In some situations we may also email the updated policy to you. Please review
                  the privacy policy whenever you visit Alltech’s website to make sure that you understand how any personal data you provide will be used.</p>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">What data subject rights apply to users of Alltech
                                websites?</h3>
                <div className="privacy-text">
                  <p>Users of Alltech websites have the right to: Access their data, to have Alltech
                  correct their data, the right of data portability, and the right to have Alltech
                  delete their data at any time. Users also have the right, and the ability, to
                                    manage their Alltech subscriptions at any time.</p>
                  <p className="list-title">Users of Alltech websites have the right to:</p>
                  <ul className="privacy-list">
                    <li><strong> Request access </strong>to the personal data (commonly known as a "data subject access request") that Alltech has about you.</li>

                    <li><strong>Request correction</strong> of the personal data that we hold about you. This right allows you to request that Alltech correct incomplete or inaccurate data Alltech holds about you.</li>

                    <li><strong>Request erasure</strong> of the personal data that we hold about you. This right allows you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which we will tell you about, if applicable, at the time of your request.</li>

                    <li><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or the legitimate interest of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms.</li>

                    <li><strong>Request a transfer</strong> of your personal data to a third party.</li>

                    <li><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
                  </ul>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">How do we collect your data?</h3>
                <div className="privacy-text">
                  <p className="list-title">We use different methods to collect data from you and about you including through:</p>

                  <ul className="wrapper-list">
                    <li><p className="list-title"><strong>Direct interactions</strong>. You may give us your [Identity and Contact] by filling in forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:</p>
                      <ul className="bullet-list">
                        <li>inquire about our products or services;</li>
                        <li>create an account on our website;</li>
                        <li>subscribe to our service or publications;</li>
                        <li>request marketing materials to be sent to you;</li>
                        <li>enter a competition, promotion or survey; or</li>
                        <li>give us feedback or contact us.</li>
                      </ul>
                    </li>
                    <li><strong>Automated technologies or interactions</strong>. As you interact with our website, we automatically collect technical data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, which we explain in further detail in our cookie policy.</li>
                  </ul>
                </div>
              </div>
              <div className="privacy-block">
                <h3 className="privacy-question">For what purpose do we collect your data?</h3>
                <div className="privacy-text">
                  <p>The personal data you share with us allows us to answer your inquiries and/or to
                  deliver to you any marketing emails and e-newsletters to which you subscribe. It
                  also provides us the information we need to contact you in agreement with the
                  consent that you give to us when your personal data is collected. We do not use your personal data for automated decision-making or profiling.</p>

                  <p>Alltech will only use your personal data for purposes that are consistent with
                  and related to the original reason why we collected your personal data. If you
                  wish for an explanation about how the processing we are doing is related to the
                  original purpose for which your personal data was collected, please contact us.</p>

                  <p>If we need to use your personal data for an unrelated purpose, we will notify you
                  and either seek your additional consent or will explain to you why we are legally required to use your personal data in the specific situation.</p>

                  <p>Please note that we may process your personal data without your knowledge or
                  consent, in compliance with the above rules, where this is required or permitted by law.</p>
                  <p className="list-title">Some examples of personal data that we collect are grouped together as follows:</p>
                  <ul className="bullet-list">
                    <li><strong>Identity Data includes</strong> [first name, maiden name, last name, username or similar identifier, and title].</li>
                    <li><strong>Contact Data includes</strong> [billing address, delivery address, email address and telephone numbers].</li>
                    <li><strong>Transaction Data includes</strong> [details about payments to and from you and other details of products and services you have purchased from us].</li>
                    <li><strong>Technical Data includes</strong> [internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access this website].</li>
                    <li><strong>Profile Data includes</strong> [your username and password, purchases or orders made by you, your interests, preferences, feedback and survey responses].</li>
                    <li><strong>Usage Data includes</strong> [information about how you use our website, products and services].</li>
                    <li><strong>Marketing and Communications Data includes</strong> [your preferences in receiving marketing from us and our third parties and your communication preferences].</li>
                  </ul>
                  <p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, or genetic and biometric data). We do not collect any information about criminal convictions and offences.</p>

                  <p>Alltech obtains contact details and other personal information regarding media
                  contacts and influencers from a variety of sources including Cision. If you wishto
                  know more about how such information is collected and used, please refer to
                  Cision’s privacy notice at <a href="https://www.cision.com/us/legal/privacy-policy/" target="_blank">https://www.cision.com/us/legal/privacy-policy/</a>.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};