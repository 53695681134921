import React, { useEffect, useState } from 'react';
import { formatDate } from '../../services';
import { TablePagination } from '../../components/TablePagination';
import { TableStub } from '../../components/TableStub';
import { useDispatch, useSelector } from 'react-redux';
import {
  fillContactFormAction,
  getApiAssessment,
  setAssessmentCurrentPageAction,
  setAssessmentItemsPerPageAction,
  sortAssessmentByAlphabetAction,
  sortAssessmentByEndaDateAction,
  sortAssessmentByStartDateAction,
} from 'store/assessment/actions';
import TableLoader from 'components/TableLoader';
import { IAppState } from 'store';
import 'assets/css/Table.scss';
import './AssessmentPage.scss';
import { NavLink } from 'react-router-dom';
import { TableFilter } from 'components/TableFilter';
import { useNotifications } from '../../hooks/useNotification';
import { NotificationsTypes } from '../../store/notifications/types';
import { Dropdown } from 'react-bootstrap';
import clsx from 'clsx';
import Api from '../../api';
import { downloadUrl, getPermissions } from './helpers';
import IconMark from '../../assets/icons/IconMark';
import IconCopy from '../../assets/icons/IconCopy';
import IconDownload from '../../assets/icons/IconDownload';
import IconUpload from '../../assets/icons/IconUpload';
import { useHistory } from 'react-router';
import { ROUTES } from '../../config/routes';
import IconReload from '../../assets/icons/IconReload';
import { SuccessModal } from '../../components/SuccessModal';
import settingsSuccess from '../../assets/images/ic-state-success.svg';
import attention from '../../assets/images/00-icons-toast-ic-attantion.svg';
import { useTranslation } from 'react-i18next';
import IconRejected from '../../assets/icons/IconRejected';

export const AssessmentPage = () => {
  useNotifications(NotificationsTypes.assessments);
  const [showModal, setShowModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showBEError, setShowBEError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const ASSESSMENTS_STATUSES = [
    t('AP_new'),
    t('AP_in_progress'),
    t('AP_complet'),
    'Reallocated',
    'Downloaded',
    t('AP_updated'),
    'Autogenerated',
    'Obsolete',
    t('AP_uploaded'),
  ];

  const ASSESSMENTS_REQUEST_STATUSES = [
    t('No Request Made'),
    t('Request Pending'),
    t('Request Rejected'),
    t('Request Approved'),
  ];

  const ASSESSMENTS_FILE_STATUSES = [null, t('File Uploaded')];

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    assessmentItems,
    displayItems,
    itemsPerPage,
    totalPagesNumber,
    isLoading,
    currentPageNumber,
    sortedByStartDate,
    sortedByEndDate,
    sortedByAlphabet,
  } = useSelector((state: IAppState) => state.assessment);

  useEffect(() => {
    dispatch(getApiAssessment());
  }, []);

  const downloadTemplate = (id, status) => {
    if (status != 3) return null;
    setLoading(true);
    Api(false)
      .get(`/api/Upload/GetUploadTemplate?assessmentID=${id}`, {
        responseType: 'blob',
      })
      .then(response => {
        downloadUrl(
          window.URL.createObjectURL(new Blob([response.data])),
          'template.xlsx',
        );
      })
      .then(response => {
        setLoading(false);
      });
  };

  const uploadFile = async (file, id) => {
    let formData = new FormData();
    if (file.name.split('.').pop() !== 'xlsx') {
      setShowErrorModal(true);
      return;
    }

    formData.append('files', file);
    formData.append('targetAssessmentID', id);
    setLoading(true);

    Api(false)
      .post(`/api/Upload/UploadTemplate`, formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(response => {
        dispatch(getApiAssessment());
        console.log(response);
        setLoading(false);
        setShowModal(true);
      })
      .catch(error => {
        setLoading(false);
        setShowBEError(true);
      });
  };

  const changeItemsPerPage = (count: number) => {
    dispatch(setAssessmentItemsPerPageAction(count));
  };

  const changeCurrentPage = (pageNumber: number) => {
    dispatch(setAssessmentCurrentPageAction(pageNumber));
  };

  const handleSortByAlphabet = () => {
    dispatch(sortAssessmentByAlphabetAction(!sortedByAlphabet));
  };

  const handleSortByStartDate = () => {
    dispatch(sortAssessmentByStartDateAction(!sortedByStartDate));
  };

  const handleSortByEndDate = () => {
    dispatch(sortAssessmentByEndaDateAction(!sortedByEndDate));
  };

  const getPermissionsAccess = async (id, status) => {
    if (status !== 0) return;
    setShowStatusModal(true);
    await getPermissions(id).then(resp => dispatch(getApiAssessment()));
    // history.push(ROUTES.contact);
    //dispatch(fillContactFormAction(true))
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (e, status, requestStatus) => {
    if (requestStatus !== 3) return;
    //if (status != -1 || requestStatus == 5) return;
    //if ((status === -1 || status === 5) && requestStatus === 3) {
    if (status === -1 || status === 2 || status === 4) {
      hiddenFileInput.current.click();
    }
  };
  const handleChange = (event, id) => {
    const fileUploaded = event.target.files[0];
    uploadFile(fileUploaded, id);
  };

  // @ts-ignore
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      // @ts-ignore
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="threedots" />
    </a>
  ));

  return (
    <div className="assessment-content">
      <div className="container-custom container">
        <div className="row">
          <div className="col-12">
            <div className="header-wrapper">
              <h2 className="table-heading">{t('AP_assesments')}</h2>
              <NavLink
                to={`/dashboard/createAssessments`}
                className="action-primary btn table-action-btn"
              >
                {t('Create New Assessment')}
              </NavLink>
            </div>
            <div className="custom-table assessments-table">
              <div className="table-header">
                <div className="assessment-wrapper">
                  <TableFilter
                    active={sortedByAlphabet}
                    text={t('AP_assesmnet')}
                    onSort={handleSortByAlphabet}
                  />
                </div>
                <div className="description-wrapper">
                  <span>{t('AP_desc')}</span>
                </div>
                <div className="date-created-wrapper">
                  <TableFilter
                    active={sortedByStartDate}
                    text={t('AP_date_cr')}
                    onSort={handleSortByStartDate}
                  />
                </div>
                <div className="date-completed-wrapper">
                  <TableFilter
                    active={sortedByEndDate}
                    text={t('AP_date_compl')}
                    onSort={handleSortByEndDate}
                  />
                </div>
                <div className="status-wrapper">
                  <span>{t('AP_status')}</span>
                </div>
                <div className="action-wrapper">
                  <span>{t('AP_action')}</span>
                </div>
              </div>
              {isLoading || loading ? (
                <TableLoader />
              ) : displayItems.length ? (
                <>
                  <div className="table-body">
                    {displayItems.map(item => {
                      const singleAssessment = item.Package === null ? true : false;
                      const primaryPackageAssessment = !singleAssessment && item.Template_ID === item.Package.PackageTemplate.PrimaryTemplateID ? true : false;
                     
                      return (
                      <div className="table-item" key={item.ID}>
                        <div className="assessment-wrapper">
                          <span>{primaryPackageAssessment ? item.Package.PackageTemplate.Name : item.TemplateName}</span>
                        </div>
                        <div className="description-wrapper">
                          <span>{item.Description}</span>
                        </div>
                        <div className="date-created-wrapper">
                          <span>
                            {formatDate(item.StartDate, 'MMMM D, YYYY')}
                          </span>
                        </div>
                        <div className="date-completed-wrapper">
                          <span>
                            {formatDate(item.EndDate, 'MMMM D, YYYY')}
                          </span>
                        </div>
                        <div className="status-wrapper">
                          <span>{ASSESSMENTS_STATUSES[item.StatusFlag]}</span>
                        </div>
                        <div className="action-wrapper">
                          <NavLink
                            to={`/dashboard/assessments/${item.ID}`}
                            className="action-primary btn table-action-btn"
                          >
                            {t('AP_open')}
                          </NavLink>
                          <div>
                            {item?.StatusFlag === 2 ? (
                              <IconMark color="green" />
                            ) : (
                              <Dropdown>
                                <Dropdown.Toggle as={CustomToggle} />
                                <Dropdown.Menu title="">
                                  <Dropdown.Item
                                    as="div"
                                    onClick={() =>
                                      getPermissionsAccess(
                                        item.ID,
                                        item?.RequestState,
                                      )
                                    }
                                    className={clsx({
                                      'cursor-active':
                                        ASSESSMENTS_REQUEST_STATUSES[
                                          item?.RequestState
                                        ] === 'No Request Made',
                                    })}
                                  >
                                    <div className="d-flex">
                                      <IconCopy
                                        color={
                                          ASSESSMENTS_REQUEST_STATUSES[
                                            item?.RequestState
                                          ] === 'Request Approved'
                                            ? '#d0d0d1'
                                            : 'black'
                                        }
                                      />
                                      <div
                                        className={clsx(
                                          'asking-for-permission-active',
                                          {
                                            'cursor-active':
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] === 'No Request Made',
                                            disabled:
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] === 'Request Approved',
                                          },
                                        )}
                                      >
                                        {t('Request Bulk Upload')}
                                      </div>
                                      {/* <IconMark
                                    color={ASSESSMENTS_REQUEST_STATUSES[item?.RequestState] === 'No Request Made' ? '#d0d0d1' : 'green'} /> */}
                                      {item?.RequestState === 1 ? (
                                        <IconReload
                                          color={
                                            ASSESSMENTS_REQUEST_STATUSES[
                                              item?.RequestState
                                            ] === 'No Request Made'
                                              ? '#d0d0d1'
                                              : 'green'
                                          }
                                        />
                                      ) : item?.RequestState === 2 ? (
                                        <IconRejected />
                                      ) : (
                                        <IconMark
                                          color={
                                            ASSESSMENTS_REQUEST_STATUSES[
                                              item?.RequestState
                                            ] === 'No Request Made'
                                              ? '#d0d0d1'
                                              : 'green'
                                          }
                                        />
                                      )}
                                    </div>
                                    <div
                                      className={clsx('request-approved', {
                                        active:
                                          ASSESSMENTS_REQUEST_STATUSES[
                                            item?.RequestState
                                          ] === 'Request Approved',
                                      })}
                                    >
                                      {
                                        ASSESSMENTS_REQUEST_STATUSES[
                                          item?.RequestState
                                        ]
                                      }
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    as="div"
                                    onClick={() =>
                                      downloadTemplate(
                                        item.ID,
                                        item?.RequestState,
                                      )
                                    }
                                  >
                                    <div className="d-flex">
                                      <IconDownload
                                        color={
                                          ASSESSMENTS_REQUEST_STATUSES[
                                            item?.RequestState
                                          ] !== 'Request Approved'
                                            ? '#d0d0d1'
                                            : 'black'
                                        }
                                      />
                                      <div
                                        className={clsx(
                                          'asking-for-permission-active',
                                          {
                                            'cursor-active disabled':
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] !== 'Request Approved',
                                          },
                                          {
                                            'cursor-active':
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] === 'Request Approved',
                                          },
                                        )}
                                      >
                                        {t('Download Template')}
                                      </div>
                                    </div>
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    as="div"
                                    onClick={e =>
                                      handleClick(
                                        e,
                                        item.FileState,
                                        item?.RequestState,
                                      )
                                    }
                                  >
                                    <div className="d-flex">
                                      <IconUpload
                                        color={
                                          ASSESSMENTS_REQUEST_STATUSES[
                                            item?.RequestState
                                          ] !== 'Request Approved'
                                            ? '#d0d0d1'
                                            : 'black'
                                        }
                                      />
                                      <div
                                        className={clsx(
                                          'asking-for-permission-active',
                                          {
                                            disabled:
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] !== 'Request Approved' ||
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] !== 'Request Rejected',
                                          },
                                          {
                                            'cursor-active':
                                              ASSESSMENTS_REQUEST_STATUSES[
                                                item?.RequestState
                                              ] === 'Request Approved',
                                          },
                                        )}
                                      >
                                        {t('Upload File')}
                                      </div>
                                      <input
                                        type="file"
                                        ref={hiddenFileInput}
                                        onChange={e => handleChange(e, item.ID)}
                                        style={{ display: 'none' }}
                                        accept=".xlsx"
                                      />
                                      {/* {item?.FileState === 1 ? <IconReload color="green" /> : item?.FileState === 4 ? <IconRejected /> : <IconMark color="#d0d0d1" />} */}
                                      {item?.FileState === 0 && (
                                        <IconMark color="green" />
                                      )}
                                      {item?.FileState === 1 && (
                                        <IconMark color="#d0d0d1" />
                                      )}
                                      {item?.FileState === 2 && (
                                        <IconReload color="green" />
                                      )}
                                      {item?.FileState === 3 && (
                                        <IconMark color="green" />
                                      )}
                                      {item?.FileState === 4 ||
                                        (item?.FileState === 5 && (
                                          <IconRejected />
                                        ))}
                                    </div>
                                    {item?.FileStateText && (
                                      <span
                                        className="request-approved"
                                        dangerouslySetInnerHTML={{
                                          __html: t(item.FileStateText),
                                        }}
                                      ></span>
                                    )}
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            )}
                          </div>
                        </div>
                      </div>
                    )})}
                  </div>
                </>
              ) : (
                <TableStub description={t('AP_view')} />
              )}
              <TablePagination
                pagesCount={totalPagesNumber}
                itemsPerPage={itemsPerPage}
                activePageNumber={currentPageNumber}
                changeItemsPerPageCount={changeItemsPerPage}
                changePage={changeCurrentPage}
                totalItems={assessmentItems.length}
              />
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        imgUrl={settingsSuccess}
        show={showModal}
        handleShowModal={setShowModal}
        modalText={'File has been uploaded successfully'}
      />
      <SuccessModal
        imgUrl={settingsSuccess}
        show={showStatusModal}
        handleShowModal={setShowStatusModal}
        modalText={t('request_modal')}
      />
      <SuccessModal
        imgUrl={attention}
        show={showBEError}
        handleShowModal={setShowBEError}
        modalText={'Please, try again or contact support'}
      />
      <SuccessModal
        imgUrl={attention}
        show={showErrorModal}
        handleShowModal={setShowErrorModal}
        modalText={'Invalid file format'}
      />
    </div>
  );
};
